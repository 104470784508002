import React, { useState, useEffect } from 'react'
import '../stylesheet/General.css'

import tocbot from 'tocbot'
import { useTranslation } from 'react-i18next'

const Privacy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const { t } = useTranslation()
  const { i18n } = useTranslation()

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    tocbot.destroy()

    // reinitialize
    tocbot.init({
      // Where to render the table of contents.
      tocSelector: '.js-toc',
      // Where to grab the headings to build the table of contents.
      contentSelector: '.js-toc-content',
      // Which headings to grab inside of the contentSelector element.
      headingSelector: 'h1, h2, h3, h4',
      scrollSmoothDuration: 100,
      headingsOffset: windowWidth < 768 ? 90 : 0, // responsive
      scrollSmoothOffset: windowWidth < 768 ? -90 : 0,
      hasInnerContainers: true,
    })

    return () => {
      window.removeEventListener('resize', handleResize)
      tocbot.destroy()
    }
  }, [windowWidth])

  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="width-750">
        <h1 className="top-adjust">
          <strong>{t('Privacy Policy')}</strong>
        </h1>
        <div className="js-toc"></div>
        <div className="js-toc-content">
          <div className="my-5">
            <h2 id="basic-principles">{t('Basic Principles')}</h2>
            <div>
              <p>{t('Basic Principles content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="scope-privacy">{t('Scope of Application')}</h2>
            <div>
              <p>{t('Scope of Application content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="purpose-of-use-of-personal-information">
              {t('Purpose of Use of Personal Information')}
            </h2>
            {i18n.language === 'ja' ? (
              <div>
                <p>
                  当サイトは、お客様からご提供いただく情報を以下の目的の範囲内において利用します。
                </p>
                <ul>
                  <li>ご本人確認のため</li>
                  <li>お問い合わせ、コメント等の確認・回答のため</li>
                  <li>メールマガジン・DM・各種お知らせ等の配信・送付のため</li>
                  <li>
                    キャンペーン・アンケート・モニター・取材等の実施のため
                  </li>
                  <li>サービスの提供・改善・開発・マーケティングのため</li>
                  <li>
                    お客さまの承諾・申込みに基づく、提携事業者・団体等への個人情報の提供のため
                  </li>
                  <li>利用規約等で禁じている行為などの調査のため</li>
                  <li>その他個別に承諾いただいた目的</li>
                </ul>
              </div>
            ) : (
              <div>
                <p>
                  Our site uses the information provided by customers within the
                  following purposes.
                </p>
                <ul>
                  <li>For identity verification</li>
                  <li>
                    For confirmation and response to inquiries, comments, etc.
                  </li>
                  <li>
                    For distribution and sending of newsletters, DMs, various
                    notifications, etc.
                  </li>
                  <li>
                    For implementation of campaigns, surveys, monitors,
                    coverage, etc.
                  </li>
                  <li>
                    For service provision, improvement, development, and
                    marketing
                  </li>
                  <li>
                    For providing personal information to affiliated business
                    operators, groups, etc. based on the consent and application
                    of the customer
                  </li>
                  <li>
                    For investigation of acts prohibited by terms of use, etc.
                  </li>
                  <li>
                    For other purposes individually consented to Management of
                    Personal Information
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="my-5">
            <h2 id="personal-information-management">
              {t('Management of Personal Information')}
            </h2>
            <div>
              <p>{t('Management of Personal Information content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="provision-of-personal-information-to-third-parties">
              {t('Third-Party Provision of Personal Information')}
            </h2>
            <div>
              <p>{t('Third-Party content')}</p>
              {i18n.language === 'ja' ? (
                <ul>
                  <li>ご本人の同意がある場合</li>
                  <li>法令に基づく場合</li>
                  <li>人の生命・身体・財産の保護に必要な場合</li>
                  <li>公衆衛生・児童の健全育成に必要な場合</li>
                  <li>
                    国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>When there is the consent of the person</li>
                  <li>When based on laws and regulations</li>
                  <li>
                    When necessary to protect the life, body, and property of a
                    person
                  </li>
                  <li>
                    When necessary for public health and sound upbringing of
                    children
                  </li>
                  <li>
                    When cooperating with the duties stipulated by laws and
                    regulations of national institutions, etc. (tax
                    investigations, statistical surveys, etc.)
                  </li>
                </ul>
              )}
              <p>{t('Third-Party sharing')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="inquiries-about-personal-information">
              {t('Inquiries Regarding Personal Information')}
            </h2>
            <div>
              <p>{t('Inquiries Regarding Personal Information content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="cookies">Cookie</h2>
            <div>
              <p>{t('Cookie content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="access-analysis">{t('Access Analysis')} </h2>
            <div>
              <p>{t('Access Analysis content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="advertisement-delivery">{t('Ad Delivery')}</h2>
            <div>
              <p>{t('Ad Delivery content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="contact-form-privacy"> {t('Contact Form')}</h2>
            <div>
              <p>{t('Contact Form content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="embedded-content">
              {t('Embedded Content from Other Sites')}
            </h2>
            <div>
              <p>{t('Embedded Content content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="disclaimer"> {t('Disclaimer')} </h2>
            <div>
              <p>{t('Disclaimer content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="copyright-portrait-rights">
              {t('Copyright and Portrait Rights')}
            </h2>
            <div>
              <p>{t('Copyright content')} </p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="links">{t('Links')} </h2>
            <div>
              <p>{t('Links content')}</p>
            </div>
          </div>
          <div className="my-5">
            <h2 id="changes-to-privacy-policy">
              {t('Changes to This Privacy Policy')}
            </h2>
            <div>
              <p>{t('Changes to This Privacy Policy content')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
