import { useTranslation } from 'react-i18next'
import ikkei from './images/ikkei.jpg'
import kai from './images/kai.jpg'
import alex from './images/alex.jpg'
import './Members.css'
import MediaQuery from 'react-responsive'

function Members() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()

  return (
    <div className="pale-background">
      <MediaQuery query="(max-width: 767px)">
        <div className="members-container">
          <div className="members-width-sp">
            <div className="member-container-sp">
              <div className="member-width-sp">
                <div className="member-profile-container">
                  <img src={ikkei} className="headshot" />
                  <div className="medium-letter mt-4">{t('ikkei-name')}</div>
                  <div className="small-letter font-bold my-1">
                    Machine Learning Scientist
                  </div>
                  <div className="small-letter font-bold my-1">@ Amazon</div>
                  <div className="small-letter px-4 pb-5">
                    <p> {t('ikkei-intro1')} </p>
                    <p> {t('ikkei-intro2')} </p>
                    <p> {t('ikkei-intro3')} </p>
                    <p> {t('ikkei-intro4')} </p>
                  </div>
                </div>
              </div>
              <div className="member-width-sp">
                <div className="member-profile-container">
                  <img src={kai} className="headshot" />
                  <div className="medium-letter mt-4">{t('tetsuro-name')}</div>
                  <div className="small-letter font-bold my-1">
                    Computer Science
                  </div>
                  <div className="small-letter font-bold my-1">
                    @ University of Southern
                  </div>
                  <div className="small-letter font-bold my-1 pb-2">
                    California
                  </div>
                  <div className="small-letter px-4 pb-5">
                    <p>{t('tetsuro-intro1')}</p>
                    <p>{t('tetsuro-intro2')}</p>
                    <p>{t('tetsuro-intro3')}</p>
                    <p>{t('tetsuro-intro4')}</p>
                  </div>
                </div>
              </div>
              <div className="member-width-sp">
                <div className="member-profile-container">
                  <img src={alex} className="headshot" />
                  <div className="medium-letter mt-4"> {t('alex-name')} </div>
                  <div className="small-letter font-bold my-1">
                    Data Science Manager
                  </div>
                  <div className="small-letter font-bold my-1">
                    @ J.P.Morgan
                  </div>
                  <div className="small-letter px-4 pb-5">
                    <p className="text-justify">{t('alex-intro1')}</p>
                    <p>{t('alex-intro2')}</p>
                    <p>{t('alex-intro3')}</p>
                    <p>{t('alex-intro4')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 768px)">
        <div className="members-container">
          <div className="members-width">
            <div className="member-container px-2">
              <div className="member-width">
                <div className="member-profile-container">
                  <img src={ikkei} className="headshot" />
                  <div className="medium-letter mt-4">{t('ikkei-name')}</div>
                  <div className="small-letter font-bold my-1">
                    Machine Learning Scientist
                  </div>
                  <div className="small-letter font-bold my-1">@ Amazon</div>
                  <div className="small-letter font-bold my-1 pb-2 invisible">
                    California
                  </div>
                  <div className="small-letter member-width px-1">
                    <p> {t('ikkei-intro1')} </p>
                    <p> {t('ikkei-intro2')} </p>
                    <p> {t('ikkei-intro3')} </p>
                    <p> {t('ikkei-intro4')} </p>
                  </div>
                </div>
              </div>
              <div className="member-width">
                <div className="member-profile-container">
                  <img src={kai} className="headshot" />
                  <div className="medium-letter mt-4">{t('tetsuro-name')}</div>
                  <div className="small-letter font-bold my-1">
                    Computer Science
                  </div>
                  <div className="small-letter font-bold my-1">
                    @ University of Southern
                  </div>
                  <div className="small-letter font-bold my-1 pb-2">
                    California
                  </div>
                  <div className="small-letter member-width px-1">
                    <p>{t('tetsuro-intro1')}</p>
                    <p>{t('tetsuro-intro2')}</p>
                    <p>{t('tetsuro-intro3')}</p>
                    <p>{t('tetsuro-intro4')}</p>
                  </div>
                </div>
              </div>
              <div className="member-width">
                <div className="member-profile-container">
                  <img src={alex} className="headshot" />
                  <div className="medium-letter mt-4">{t('alex-name')}</div>
                  <div className="small-letter font-bold my-1">
                    Data Science Manager
                  </div>
                  <div className="small-letter font-bold my-1">
                    @ J.P.Morgan
                  </div>
                  <div className="small-letter font-bold my-1 pb-2 invisible">
                    California
                  </div>
                  <div className="small-letter member-width px-1">
                    <p className="text-justify">{t('alex-intro1')}</p>
                    <p>{t('alex-intro2')}</p>
                    <p>{t('alex-intro3')}</p>
                    <p>{t('alex-intro4')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

export default Members
