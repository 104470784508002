import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './stylesheet/General.css'
import 'flag-icon-css/css/flag-icons.min.css'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import '../src/stylesheet/General.css'
import '../src/stylesheet/Languages.css'

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'us',
  },
  {
    code: 'ja',
    name: '日本語',
    country_code: 'jp',
  },
]

function Languages() {
  const [activeLang, setActiveLang] = useState(i18next.language || 'ja')
  const { t } = useTranslation()

  const getLanguageName = (code) => {
    const lang = languages.find((lang) => lang.code === code)
    return lang ? lang.name : 'English'
  }

  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang)
    setActiveLang(lang)
    console.log(`new language: ${lang}`)
  }

  return (
    <div className="container top-adjust-padding">
      <div className="row justify-content-center">
        <div className="col col-md-4 text-center">
          <div className="card shadow-sm h-100 d-flex flex-column">
            <div className="card-header">
              <h4 className="my-0 text-center">{t('Preferred Language')}</h4>
            </div>
            <div className="card-body card-body-container">
              <ul className="list-unstyled mt-3 mb-0">
                {languages.map(({ code, name, country_code }) => (
                  <li
                    key={country_code}
                    className={`${
                      activeLang === code ? 'active' : ''
                    } li-height-3`}
                    onClick={() => changeLanguage(code)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                    ></span>
                    {name}
                    {activeLang === code && (
                      <i className="bi bi-check text-primary ms-2"></i>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-4">
              {t('Your preferred language is ')}
              <span className="text-primary">
                {getLanguageName(activeLang)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Languages
