import { Routes, Route } from 'react-router-dom'
import Contents from './Contents'
import Navbar from './Navbar'
import Footer from './Footer'
import '../src/stylesheet/Home.css'
import Languages from './Language'
import Privacy from './policies/Privacy'
import TermsOfService from './policies/TermsOfService'

function Home() {
  return (
    <>
      <div className="content">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Contents />
              </>
            }
          />
          <Route path="/language-setting" element={<Languages />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
}

export default Home
